import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import '../App.css'


// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {



  // OR
  // -------------------------------------------------------
  const logo = <Box component="img"  className = "App-logo" src="/favicon/JR-logo-512x512.png" sx={{ alignItems: 'center',width: 100, height: 40, ...sx }} /> 
  



  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
