import apiClient from "./Client";
import { config } from 'src/partnerconfig';

const getStatesData = (country) => {
    //console.log("getting states from country", country);
    const stateapi = `${config.jrmGetStates}/?country=${country}`;
    return apiClient.get(stateapi);
}
const getDistrictsData = (country, state) => {
    //console.log ("districtlist", config.jrmGetDistricts);
    const districtapi = `${config.jrmGetDistricts}/?country=${country}&state=${state}`;
    //console.log('getdistrictdata',districtapi);
    return apiClient.get(districtapi);
};

const getCitiesData = (country, state) => {
    //console.log ("citylist", config.jrmGetCities);
    const citiesapi = `${config.jrmGetCities}/?country=${country}&state=${state}`;
    //console.log('getcitiesdata',citiesapi);
    return apiClient.get(citiesapi);
};
const getCountriesData = () => {
  //console.log('getting countries');
  const countriesApi = `${config.jrmGetCountries}`;
  return apiClient.get(countriesApi);
};


export { getStatesData, getDistrictsData, getCitiesData, getCountriesData };
