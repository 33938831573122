import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import family from '../Translation/En/family.json';
import familyTa from '../Translation/Ta/familyta.json';
import business from '../Translation/En/business.json';
import businessTa from '../Translation/Ta/business.json';
import child from '../Translation/En/child.json';
import childTa from '../Translation/Ta/child.json';
import Youth from '../Translation/En/Youth.json';
import YouthTa from '../Translation/Ta/Youth.json';
import common from '../Translation/En/common.json';
import commonTa from '../Translation/Ta/common.json';
import revival from '../Translation/En/revival.json';
import revivalTa from '../Translation/Ta/revival.json';
import JRMag from '../Translation/En/JRMag.json';
import JRMagTa from '../Translation/Ta/JRMag.json';
import kids from '../Translation/En/kids.json';
import kidsTa from '../Translation/Ta/kids.json';
import youthmag from '../Translation/En/youthmag.json';
import youthmagTa from '../Translation/Ta/youthmag.json';
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  //   .use(Backend)
  .init({
    resources: {
      en: {
        family: family,
        business: business,
        child: child,
        Youth: Youth,
        common: common,
        revival: revival,
        JRMag: JRMag,
        kids: kids,
        youthmag:youthmag
      },
      ta: {
        family: familyTa,
        business: businessTa,
        child: childTa,
        Youth: YouthTa,
        common: commonTa,
        revival: revivalTa,
        JRMag: JRMagTa,
        kids: kidsTa,
        youthmag:youthmagTa
      },
    },
    ns: ['family','business','child','Youth','common','revival','JRMag','kidsTa'],
    defaultNS: ['family','common'],
    lng: localStorage.getItem('lang'),

    debug: true,
    fallbackLng: 'en', // use de if selected language is not available
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
