// routes
import { PATH_PAGE, PATH_SHOP,PATH_DASHBOARD } from '../../routes/paths';

// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Events',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    path: PATH_PAGE.events,
  },
  {
    title: 'Branches',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    path: PATH_PAGE.gpc,
  },
  
  {
    title: 'Shop',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    path: PATH_SHOP,
  },
  // {
  //   items: [
  //     // Blessing Plan
  //     {
  //       title: 'Prayer Plan',
  //       icon: <Iconify icon={'icon-park-outline:bless'} {...ICON_SIZE} />,
  //       children: [
  //         { title: 'Family Blessing', path: PATH_DASHBOARD.plan.fbp },
  //         { title: 'Business Blessing', path: PATH_DASHBOARD.plan.bbp },
  //         { title: 'Children Blessing', path: PATH_DASHBOARD.plan.cbp },
  //         { title: 'Youth Blessing', path: PATH_DASHBOARD.plan.foj },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Contact',
  //   icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
  //   path: PATH_PAGE.contact,
  // },
  // {
  //   title: 'Profile',
  //   path: '/auth',
  //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
  //   children: [
  //     {
  //       subheader: 'Authentication',
  //       items: [
  //         { title: 'Login', path: PATH_AUTH.login },
  //         { title: 'Register', path: PATH_AUTH.register },
  //         { title: 'Forgot password', path: PATH_AUTH.eForgotPassword },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   title: 'Pages',
  //   path: '/pages',
  //   icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
  //   children: [
  //     {
  //       subheader: 'Other',
  //       items: [
  //         { title: 'About us', path: PATH_PAGE.about },
  //         { title: 'Contact us', path: PATH_PAGE.contact },
  //         { title: 'FAQs', path: PATH_PAGE.faqs },
  //       ],
  //     },
  //     {
  //       subheader: 'Authentication',
  //       items: [
  //         { title: 'Login', path: PATH_AUTH.login },
  //         { title: 'Register', path: PATH_AUTH.register },
  //         { title: 'Forgot password', path: PATH_AUTH.eForgotPassword },
  //       ],
  //     },
  //     {
  //       subheader: 'Dashboard',
  //       items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
  //     },
  //   ],
  // },
];

export default menuConfig;
