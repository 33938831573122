import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';


let date = new Date();
let currentyear = date.getFullYear();
// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: 'JRM',
  //   children: [
  //     { name: 'About us', href: PATH_PAGE.about },
  //     { name: 'Contact us', href: PATH_PAGE.contact },
  //    // { name: 'FAQs', href: PATH_PAGE.faqs },
  //   ],
  // },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.privacypolicy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: '+91 4639 353333', href:"tel:+914639353333" },
      { name: '+91 4639 220000', href:"tel:+914639220000" },
      { name: 'info@jesusredeems.org', href:"mailto:info@jesusredeems.org" },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.light,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <br/>
    <Container sx={{ pb: 1 ,mt:{md:5}}}>
    <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } , mb:5}}
        >

          <Grid item xs={6} md={3}>
          <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            <Typography variant="body2" sx={{ pr: { md: 5 },  }}>
            Download our app on
            </Typography>

            <Stack
              direction={{xs:'column', md:'row'}}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 1, mb: { xs: 1, md: 0 } }}
            >
            <IconButton href="https://play.google.com/store/apps/details?id=com.jesusredeems.jrmapp" target="_blank" rel="noreferrer">
                  <Iconify icon={'logos:google-play-icon'} width={40} height={40} />
                </IconButton>
                <IconButton href="https://apps.apple.com/us/app/jesus-redeems-ministries/id6446440478" target="_blank" rel="noreferrer">
                  <Iconify icon={'logos:apple-app-store'} width={40} height={40} />
                </IconButton>
          
            </Stack>
          </Grid>

          <Grid item xs={6} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline} 
                  </Typography>
                  {list.headline === 'Contact' ? (
<>
                  {list.children.map((link) => (
                    <Link
                      href={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      rel="noreferrer"
                      target="_blank"
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                  </>
                  ) : (<>
                    {list.children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        target="_blank"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                    </>)
                  }
                </Stack>
              ))}
              <Stack spacing={2}>
                <Typography component="p" variant="overline">
                  Our Address
                </Typography>
                <Typography color="inherit" variant="body2">Tabernacle of God</Typography>
                <Typography color="inherit" variant="body2">Nalumavadi, Tuticorin</Typography>
                <Typography color="inherit" variant="body2">Tamil Nadu - 628211</Typography>
                <Typography color="inherit" variant="body2">India</Typography>
              </Stack>

            </Stack>
          </Grid>
        </Grid>
        <Divider />
      <Typography  component="p"  variant="body2"
          sx={{
            m:1,
            fontSize: 15,
            textAlign: { xs: 'center', md: 'center' },
          }}  >
          © Jesus Redeems Ministries | {currentyear}
        </Typography>
        
      </Container>
    </RootStyle>
  );
}
