// ----------------------------------------------------------------------


function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verifyEmail: path(ROOTS_AUTH, '/verify-email'),
  verifyMobile: path(ROOTS_AUTH, '/verify-mobile'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/set-password'),
  eForgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  mForgotPassword: path(ROOTS_AUTH, '/m.forgot-password'),
  
};

export const PATH_PAGE = {
  home:"/",
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  terms : '/termsofuse',
  privacypolicy:'/privacypolicy',
  // faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  events: '/events',
  gpc: '/gpc',
 
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    myapp: path(ROOTS_DASHBOARD, '/myapp'),
    events: path(ROOTS_DASHBOARD, '/events'),
    plans: path(ROOTS_DASHBOARD, '/plans'),
    eventmgmt: path(ROOTS_DASHBOARD, '/jrms/eventmgmt'),
    donate: path(ROOTS_DASHBOARD, '/donate'),
    forms: path(ROOTS_DASHBOARD, '/forms'),
  },
  events:{
    rf25: path(ROOTS_DASHBOARD, '/event/revival-festivals'),
    wrvc: path(ROOTS_DASHBOARD, '/event/wr-vision-camp'),
    twr: path(ROOTS_DASHBOARD, '/event/tw-revival'),
  },
  // forms: {
  //   root: path(ROOTS_DASHBOARD, '/forms'),
  //   dailybible: path(ROOTS_DASHBOARD, '/forms/dailybible'),
  //   videoFeed: path(ROOTS_DASHBOARD, '/forms/videofeed'),
  //   event: path(ROOTS_DASHBOARD, '/forms/event'),
  //   magazine: path(ROOTS_DASHBOARD, '/forms/magazine'),
  //   resources: path(ROOTS_DASHBOARD, '/forms/resources'),
  //   prayercenter: path(ROOTS_DASHBOARD, '/forms/prayercenter'),
  // },
  forms: {
    root: path(ROOTS_DASHBOARD, '/forms'),
    addbibleverse: path(ROOTS_DASHBOARD, '/forms/addbibleverse'),
    editbibleverse: (id) => path(ROOTS_DASHBOARD, `/forms/editbibleverse/${id}`),
    listbibleverse: path(ROOTS_DASHBOARD, '/forms/listbibleverse'),

    addvideofeed: path(ROOTS_DASHBOARD, '/forms/addvideofeed'),
    editvideofeed: (id) => path(ROOTS_DASHBOARD, `/forms/editvideofeed/${id}`),
    listvideofeed: path(ROOTS_DASHBOARD, '/forms/listvideofeed'),

    addevents: path(ROOTS_DASHBOARD, '/forms/addevents'),
    editevents: (id) => path(ROOTS_DASHBOARD, `/forms/editevents/${id}`),
    listevents: path(ROOTS_DASHBOARD, '/forms/listevents'),
    // member: path(ROOTS_DASHBOARD, '/user/member'),

    addmagazine: path(ROOTS_DASHBOARD, '/forms/addmagazine'),
    editmagazine: (id) => path(ROOTS_DASHBOARD, `/forms/editmagazine/${id}`),
    listmagazine: path(ROOTS_DASHBOARD, '/forms/listmagazine'),

    addresources: path(ROOTS_DASHBOARD, '/forms/addresources'),
    editresources: (id) => path(ROOTS_DASHBOARD, `/forms/editresources/${id}`),
    listresources: path(ROOTS_DASHBOARD, '/forms/listresources'),

    addbranch: path(ROOTS_DASHBOARD, '/forms/addbranch'),
    editbranch: (id) => path(ROOTS_DASHBOARD, `/forms/editbranch/${id}`),
    listbranch: path(ROOTS_DASHBOARD, '/forms/listbranch'),
  },
  // forms: {/dashboard/forms/editresources
  //   root: path(ROOTS_DASHBOARD, '/forms'),
  //   addvideofeed: path(ROOTS_DASHBOARD, '/forms/addvideofeed'),
  //   editvideofeed: path(ROOTS_DASHBOARD, '/forms/editvideofeed'),
  //   listvideofeed: path(ROOTS_DASHBOARD, '/forms/listvideofeed'),
  // },

  plan: {
    fbp: path(ROOTS_DASHBOARD, '/plan/family-blessing'),
    bbp: path(ROOTS_DASHBOARD, '/plan/business-blessing'),
    cbp: path(ROOTS_DASHBOARD, '/plan/children-blessing'),
    foj: path(ROOTS_DASHBOARD, '/plan/youth-foj'),
  },

  revival: {
    rpp: path(ROOTS_DASHBOARD, '/revival/rpp'),
  },

  magazine: {
    jrmag: path(ROOTS_DASHBOARD, '/magazine/jr'),
    sjmmag: path(ROOTS_DASHBOARD, '/magazine/kids'),
    vumag: path(ROOTS_DASHBOARD, '/magazine/youth'),
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    spouse: path(ROOTS_DASHBOARD, '/user/spouse'),
    child: path(ROOTS_DASHBOARD, '/user/child'),
    member: path(ROOTS_DASHBOARD, '/user/member'),
  },

  // invoice: {
  //   root: path(ROOTS_DASHBOARD, '/invoice'),
  //   list: path(ROOTS_DASHBOARD, '/invoice/list'),
  //   new: path(ROOTS_DASHBOARD, '/invoice/new'),
  //   view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
  //   edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
  //   demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  // },
  mainmenu: {
    //  home: path(ROOTS_DASHBOARD, '/mainmenu/home'),
    events: path(ROOTS_DASHBOARD, '/mainmenu/eventslist'),
    contact: path(ROOTS_DASHBOARD, '/mainmenu/contactus'),
    gpc: path(ROOTS_DASHBOARD, '/mainmenu/gpc'),
  },
};
export const PATH_SHOP = 'https://www.jrbibleworld.in/';

