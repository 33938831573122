// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const ICON_SIZE = {
  width: 22,
  height: 22,

};
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIcons = (name) => <Iconify icon={`${name}`} {...ICON_SIZE} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.myapp,
        icon: ICONS.dashboard,
      },

      // children:[
      //   { title: 'Event management', path: PATH_DASHBOARD.general.eventmgmt },
      // ]
      // },
    ],
  },
 {
    items: [
      // Events
      {
        title: 'Events',
        icon: <Iconify icon={'icon-park-outline:bless'} {...ICON_SIZE} />,
        children: [
          { title: 'Revival Festivals', path: PATH_DASHBOARD.events.rf25 },
          { title: 'WR Vision Camp', path: PATH_DASHBOARD.events.wrvc },
          
        ],
      },
    ],
  },
  // {
  //   items: [
  //     // Blessing Plan
  //     {
  //       title: 'Prayer Plan',
  //       icon: <Iconify icon={'icon-park-outline:bless'} {...ICON_SIZE} />,
  //       children: [
  //         { title: 'Family Blessing', path: PATH_DASHBOARD.plan.fbp },
  //         { title: 'Business Blessing', path: PATH_DASHBOARD.plan.bbp },
  //         { title: 'Children Blessing', path: PATH_DASHBOARD.plan.cbp },
  //         { title: 'Youth Blessing', path: PATH_DASHBOARD.plan.foj },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   items: [
  //     // Revival Plan
  //     {
  //       title: 'Revival Plan',
  //       icon: <Iconify icon={'mdi:fire'} {...ICON_SIZE} />,
  //       children: [
  //         { title: 'Prayer Partner(RPP)', path: PATH_DASHBOARD.revival.rpp },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   items: [
  //     // Magazine Subscription
  //     {
  //       title: 'Magazine',
  //       icon: (
  //         <Iconify
  //           icon={'material-symbols:menu-book-outline-sharp'}
  //           {...ICON_SIZE}
  //         />
  //       ),
  //       children: [
  //         { title: 'JR Main', path: PATH_DASHBOARD.magazine.jrmag },
  //         { title: 'Kids', path: PATH_DASHBOARD.magazine.sjmmag },
  //         { title: 'Youth', path: PATH_DASHBOARD.magazine.vumag },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   items: [
  //     // Magazine Subscription
  //     {
  //       title: 'Forms',
  //       icon: (
  //         <Iconify
  //           icon={'material-symbols:menu-book-outline-sharp'}
  //           {...ICON_SIZE}
  //         />
  //       ),
  //       children: [
  //         {
  //           title: 'Dailybible',
  //           path: PATH_DASHBOARD.forms.addbibleverse,
  //         },
  //         {
  //           title: 'Video Feed',
  //           path: PATH_DASHBOARD.forms.addvideofeed,
  //         },

  //         {
  //           title: 'Events',
  //           path: PATH_DASHBOARD.forms.addevents,
  //         },
  //         {
  //           title: 'Magazine',
  //           path: PATH_DASHBOARD.forms.addmagazine,
  //         },
  //         {
  //           title: 'Resources',
  //           path: PATH_DASHBOARD.forms.addresources,
  //         },
  //         {
  //           title: 'Branches',
  //           path: PATH_DASHBOARD.forms.addbranch,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { items:[{title: 'invoice',
  // path: PATH_DASHBOARD.invoice.root,
  // icon: ICONS.invoice,
  // children: [
  //   { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //   { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //   { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //   { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  // ],}]

  // },

  // ----------------------------------------------------------------------

  // {
  //   subheader : 'Main Menu',
  //   items: [

  //   {
  //     title: 'Events',
  //     icon: <Iconify icon={'material-symbols:event-available-sharp'} {...ICON_SIZE} />,
  //     path: PATH_DASHBOARD.mainmenu.events,
  //   },
  //   {
  //     title: 'Branches',
  //     icon: <Iconify icon={'material-symbols:location-on-outline-rounded'} {...ICON_SIZE} />,
  //     path: PATH_DASHBOARD.mainmenu.gpc,
  //   },
  //   {
  //     title: 'JR Shop',
  //     icon: <Iconify icon={'ic:outline-local-grocery-store'} {...ICON_SIZE} />,
  //     path: 'https://www.jrbibleworld.com/',
  //   },
  // ],

  // },
];

export default navConfig;
