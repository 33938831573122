import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import EditDailyBibleVerse from 'src/Dynamic Forms/dailybibleverse/editbibleverse';
//import Editevents from 'src/Dynamic Forms/Events/editevents';
// import EventTab from 'src/pages/dashboard/Dailybibleverse/eventTab';
// import UserAccount from 'src/pages/dashboard/Dailybibleverse/dailybible';
// import FeedTab from 'src/pages/dashboard/Dailybibleverse/feedtab';


// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'set-password', element: <SetPassword /> },
        { path: 'verify-email', element: <VerifyCodeEmail /> },
        { path: 'verify-mobile', element: <VerifyCodeMobile /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'register', element: <Register /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'myapp', element: <GeneralApp1 /> },
        { path: 'events', element: <Events /> },
        { path: 'plans', element: <Plans /> },
        { path: 'donate', element: <Donation /> },

        // jrms
        {
          path: 'jrms',
          children: [{ path: 'eventmgmt', element: <EventManagement /> }],
        },
        {
          path: 'plan',
          children: [
            { element: <Navigate to="/dashboard/plan" replace />, index: true },
            { path: 'family-blessing', element: <FamilyBlessing /> },
            { path: 'business-blessing', element: <BusinessBlessing /> },
            { path: 'children-blessing', element: <ChildrenBlessing /> },
            { path: 'youth-foj', element: <FriendOfJesus /> },
          ],
        },
        {
          path: 'event',
          children: [{ path: 'revival-festivals', element: <RF25 /> },
            { path: 'wr-vision-camp', element: <WRVC1 /> },
            { path: 'tw-revival', element: <TWR /> },
          ],
          
        },
        {
          path: 'revival',
          children: [{ path: 'rpp', element: <RPP /> }],
        },
        {
          path: 'magazine',
          children: [
            { path: 'jr', element: <JRMagazine /> },
            { path: 'kids', element: <KidsMagazine /> },
            { path: 'youth', element: <YouthMagazine /> },
          ],
        },
        // {
        //   path: 'forms',
        //   children: [
        //     { path: 'adddailybible', element: <Dailybible /> },
        //     { path: 'addvideoFeed', element: <Videofeed /> },
        //     { path: 'event', element: <EventForm /> },
        //     { path: 'magazine', element: <Subcriptionform /> },
        //     { path: 'resources', element: <Resources /> },
        //     { path: 'prayercenter', element: <Prayercenter /> },
        //   ],
        // },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'profile', element: <UserProfile /> },
            { path: 'spouse', element: <UserProfileSpouse /> },
            { path: 'child', element: <UserProfileChildren /> },
            { path: 'member', element: <UserProfileMember /> },
          ],
        },
        {
          path: 'forms',
          children: [
            {
              element: (
                <Navigate to="/dashboard/dashboard/forms/dailybible" replace />
              ),
              index: true,
            },
            { path: 'addbibleverse', element: <UserAccount /> },
            { path: 'editbibleverse/:id', element: <EditBibleVerse /> },
            { path: 'addvideoFeed', element: <FeedTab /> },
             { path: 'editvideoFeed/:id', element: <EditVideofeed /> },
            { path: 'addevents', element: <EventTab /> },
             { path: 'editevents/:id', element: <Editevents /> },
            { path: 'addmagazine', element: <MagazineTab /> },
             { path: 'editmagazine/:id', element: <Editmagazine /> },
            { path: 'addresources', element: <ResourcesTab /> },
             { path: 'editresources/:id', element: <Editresources /> },
            { path: 'addbranch', element: <BranchTab /> },
             { path: 'editbranch/:id', element: <Editbranch /> },
            { path: 'member', element: <UserProfileMember /> },
          ],
        },

        // {
        //   path: 'invoice',
        //   children: [
        //     { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
        //     { path: 'list', element: <InvoiceList /> },
        //     { path: ':id', element: <InvoiceDetails /> },
        //     { path: ':id/edit', element: <InvoiceEdit /> },
        //     { path: 'new', element: <InvoiceCreate /> },
        //   ],
        // },
        {
          path: 'mainmenu',
          children: [
            { path: 'contactus', element: <Contact /> },
            { path: 'eventslist', element: <Events /> },
            { path: 'gpc', element: <Branches /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'contact', element: <Contact /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/auth/login" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Home />, index: true },
        { path: 'contact-us', element: <Contact /> },
        { path: 'events', element: <Events /> },
        { path: 'gpc', element: <Branches /> },
        { path: 'jrm', element: <JRM /> },
       
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'termsofuse', element: <Terms /> },
        { path: 'privacypolicy', element: <Privacy /> },
       
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const VerifyCodeEmail = Loadable(lazy(() => import('../pages/auth/VerifyCodeEmail')));
const VerifyCodeMobile = Loadable(lazy(() => import('../pages/auth/VerifyCodeMobile')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const SetPassword = Loadable(lazy(() => import('../pages/auth/SetPassword')));

// FORMS
// Blessing Plan
const FamilyBlessing = Loadable(lazy(() => import('../pages/form/FamilyBlessing')));
const BusinessBlessing = Loadable(lazy(() => import('../pages/form/BusinessBlessing')));
const ChildrenBlessing = Loadable(lazy(() => import('../pages/form/ChildrenBlessing')));
const FriendOfJesus = Loadable(lazy(() => import('../pages/form/FriendOfJesus')));

// Revival Plan
const RPP = Loadable(lazy(() => import('../pages/form/RevivalPlan/RevivalPrayerPlan')));
// Subscription Plan
const JRMagazine = Loadable(lazy(() => import('../pages/form/Magazine/JRMagazine')));
const KidsMagazine = Loadable(lazy(() => import('../pages/form/Magazine/KidsMagazine')));
const YouthMagazine = Loadable(lazy(() => import('../pages/form/Magazine/YouthMagazine')));

// Event Management
const EventManagement = Loadable(lazy(() => import('../pages/form/EventManagement')));

//Forms
// const Dailybible = Loadable(lazy(() => import('../Dynamic Forms/addbibleverse form')));
// const Videofeed = Loadable(lazy(() => import('../Dynamic Forms/videofeed')));
// const EventForm = Loadable(lazy(() => import('../Dynamic Forms/Eventsform')));
// const Subcriptionform = Loadable(lazy(() => import('../Dynamic Forms/magazineform')));        
// const Resources = Loadable(lazy(() => import('../Dynamic Forms/resources')));
// const Prayercenter = Loadable(lazy(() => import('../Dynamic Forms/Prayercenter'))); 



const UserAccount = Loadable(lazy(() => import('../pages/dashboard/Dailybibleverse/dailybible')));
const FeedTab = Loadable(lazy(() => import('../pages/dashboard/Dailybibleverse/feedtab')));
const EventTab = Loadable(lazy(() => import('../pages/dashboard/Dailybibleverse/eventTab')));
const MagazineTab = Loadable(lazy(() => import('../pages/dashboard/Dailybibleverse/magazineTab')));
const ResourcesTab = Loadable(lazy(() => import('../pages/dashboard/Dailybibleverse/ResourcesTab')));
const BranchTab = Loadable(lazy(() => import('../pages/dashboard/Dailybibleverse/BranchTab')));
//Edit
const Editmagazine = Loadable(lazy(() => import('../Dynamic Forms/Magazine/editmagazine'))); //
const Editevents = Loadable(lazy(() => import('../Dynamic Forms/Events/editevents')));
const EditBibleVerse = Loadable(lazy(() => import('../Dynamic Forms/dailybibleverse/editbibleverse')));
const EditVideofeed = Loadable(lazy(() => import('../Dynamic Forms/Videofeed/videofeededit')));
const Editbranch = Loadable(lazy(() => import('../Dynamic Forms/Branch/editbranch'))); 
const Editresources = Loadable(lazy(() => import('../Dynamic Forms/Resources/editresources')));
// Donation
const Donation = Loadable(lazy(() => import('../pages/dashboard/Donation')));

// GENERAL
const GeneralApp1 = Loadable(lazy(() => import('../pages/dashboard/GeneralApp1')));
const Plans = Loadable(lazy(() => import('../pages/dashboard/Plans')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserProfileSpouse = Loadable(lazy(() => import('../pages/dashboard/UserProfileSpouse')));
const UserProfileChildren = Loadable(lazy(() => import('../pages/dashboard/UserProfileChildren')));
const UserProfileMember = Loadable(lazy(() => import('../pages/dashboard/UserProfileMember')));
// INVOICE
// const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
// const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
// const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
// const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// EVENTS
const RF25 = Loadable(lazy(() => import('../pages/dashboard/RevivalFestivals')));
const WRVC1 = Loadable(lazy(() => import('../pages/form/Registrations/InternationalCamp/Phase1')));
const TWR = Loadable(lazy(() => import('../pages/form/Registrations/TrueWidow')));



// MAIN
const JRM = Loadable(lazy(() => import('../pages/auth/LocalLogin')));
 const Home = Loadable(lazy(() => import('../pages/Home')));
 const Contact = Loadable(lazy(() => import('../pages/Contact')));
 const Events = Loadable(lazy(() => import('../pages/Events')));
 const Branches = Loadable(lazy(() => import('../pages/Branches')));
 const Terms = Loadable(lazy(() => import('../pages/TermsAndConditions')));
 const Privacy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
 const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
 const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
