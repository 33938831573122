 //const jrmClientUrl = process.env.NODE_ENV === 'production' ? "https://partnerservice-stage.jesusredeems.com/" : "http://10.20.1.103:8080/" ;
const jrmClientUrl ="https://partnerservice-stage.jesusredeems.com/";

const config = 
{
  "baseUrl": jrmClientUrl,
  "jrmPartnerRegisterMobileUrl": jrmClientUrl+"jrms/v1/partners/mobilesignup",
  "jrmPartnerLoginUrl": jrmClientUrl+"jrms/v1/partners/signin",
  "jrmVideoFeedUrl": jrmClientUrl+"jrms/v1/feed/videos",
  "jrmEventsFeedUrl": jrmClientUrl+"jrms/v1/feed/events",
  "jrmPartnerProfileUrl": jrmClientUrl+"jrms/v1/partners",
 
  "jrmPartnerVerifyMobileUrl" : jrmClientUrl+"jrms/v1/partners/verifymobileotp",
  "jrmPartnerVerifyEmailUrl" : jrmClientUrl+"jrms/v1/partners/verifyemailotp",

  "jrmInitPasswordResetUrl": jrmClientUrl+"jrms/v1/partners/initpasswordreset",
  "jrmVerifyOTPUrl": jrmClientUrl+"jrms/v1/partners/verifypasswordotp",
  "jrmResendOTPUrl": jrmClientUrl+"jrms/v1/partners/resendotp",

  "jrmEventRegistrationUrl": jrmClientUrl+"jrms/v1/event-management/events",

  "jrmPartnerDonationSericeUrl": jrmClientUrl+"jrms/v1/donations/partners",
  "jrmPartnerBlessingPlanSericeUrl": jrmClientUrl+"jrms/v1/blessingplans/partners",
  "jrmFeedServiceUrl": jrmClientUrl+"jrms/v1/feed/home",
  "jrmPrayerServiceUrl": jrmClientUrl+"jrms/v1/feed/prayers",

  // "jrmGetCountries": jrmClientUrl+"jrms/v1/lookup/country",
  // "jrmGetStates": jrmClientUrl+"jrms/v1/lookup/states",
  // "jrmGetDistricts": jrmClientUrl+"jrms/v1/lookup/districts",
  // "jrmGetCities": jrmClientUrl+"jrms/v1/lookup/cities",
   "jrmGetCountries": "https://partnerservice-stage.jesusredeems.com/jrms/v1/lookup/country",
  "jrmGetStates": "https://partnerservice-stage.jesusredeems.com/jrms/v1/lookup/states",
  "jrmGetDistricts": "https://partnerservice-stage.jesusredeems.com/jrms/v1/lookup/districts",
  "jrmGetCities": "https://partnerservice-stage.jesusredeems.com/jrms/v1/lookup/cities"

}

export {
  config, jrmClientUrl
}
