// import PropTypes from 'prop-types';
// import { m } from 'framer-motion';
// // @mui
// import { Container, Typography } from '@mui/material';
// // hooks

// // components
// import { MotionContainer, varBounce } from '../components/animate';
// // assets
// import { ForbiddenIllustration } from '../assets';

// // ----------------------------------------------------------------------

// RoleBasedGuard.propTypes = {
//   hasContent: PropTypes.bool,
//   roles: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
//   children: PropTypes.node.isRequired,
// };

// export default function RoleBasedGuard({ hasContent, roles, children }) {
//   // Logic here to get current user role
  
  
//   // const currentRole = 'user';
//  // const currentRole = roles; // admin;

//   if (typeof roles !== 'undefined' && !roles.includes('admin')) {
//     return hasContent ? (
//       <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
//         <m.div variants={varBounce().in}>
//           <Typography variant="h3" paragraph>
//             Permission Denied - current role {roles}
//           </Typography>
//         </m.div>

//         <m.div variants={varBounce().in}>
//           <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
//         </m.div>

//         <m.div variants={varBounce().in}>
//           <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
//         </m.div>
//       </Container>
//     ) : null;
//   }

//   return <>{children}</>;
// }
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// hooks

// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
  children: PropTypes.node.isRequired,
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
  // Logic here to get current user role
  // For demonstration, assume currentUserRole is fetched from context or some other mechanism
  //const currentUserRole = 'user'; // Replace this with your actual logic to get the current user's role
  // Retrieve the user's role from local storage
  const currentUserRole = localStorage.getItem('role');

  // Console log the current user's role
  // console.log('Current user role:', currentUserRole);

  if (typeof roles !== 'undefined') {
    // Check if the current user's role includes "admin" or any of the specified roles
    if (!roles.includes('admin') && !roles.includes(currentUserRole)) {
      // If the current user's role is not admin or any of the allowed roles
      return hasContent ? (
        <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Permission Denied - current role {currentUserRole}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
          </m.div>
        </Container>
      ) : null;
    }
  }

  return <>{children}</>;
}
